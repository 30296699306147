import { Controller } from "@hotwired/stimulus";
function debounce(callback, delay) {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), delay);
  };
}
class src_default extends Controller {
  initialize() {
    this.autogrow = this.autogrow.bind(this);
  }
  connect() {
    this.element.style.overflow = "hidden";
    const delay = this.resizeDebounceDelayValue;
    this.onResize = delay > 0 ? debounce(this.autogrow, delay) : this.autogrow;
    this.autogrow();
    this.element.addEventListener("input", this.autogrow);
    window.addEventListener("resize", this.onResize);
  }
  disconnect() {
    window.removeEventListener("resize", this.onResize);
  }
  autogrow() {
    this.element.style.height = "auto";
    this.element.style.height = `${this.element.scrollHeight}px`;
  }
}
src_default.values = {
  resizeDebounceDelay: {
    type: Number,
    default: 100
  }
};
export { src_default as default };
